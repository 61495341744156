<template>
  <div class="mt-12">
    <v-row>
      <v-col cols="3" v-show="$vuetify.breakpoint.lgAndUp">
        <v-layout justify-start column class="ml-12 mr-12">
          <div style="border-right: 1px solid">
            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
            <a href="#api_usage">
            <div text rounded style="font-weight: 500; color: black" class="button" hover >
              <v-icon class="mr-2 mt-5 mb-5" color="black" small
                >bar_chart</v-icon
              >API usage
            </div></a>
            <a href="#subscription_plan">
            <div text rounded style="font-weight: 500;color: black" class="button" hover >
              <v-icon class="mr-2 mt-5 mb-5" color="black" small
                >graphic_eq</v-icon
              >My subscriptions
            </div>
            </a>
            <a href="#account_details">
            <div text rounded style="font-weight: 500;color: black" class="button" hover>
              <v-icon class="mr-2 mt-5 mb-5" color="black" small>person</v-icon
              >My account details
            </div>
            </a>
            <a>
            <div text rounded style="font-weight: 500;color: black" class="button" hover>
              <v-icon class="mr-2 mt-5 mb-5" color="black" small>payment</v-icon
              >Payment details
            </div>
            </a>
            <div
              text
              rounded
              style="font-weight: 500"
              class="button"
              hover
              @click="logout"
            >
              <v-icon class="mr-2 mt-4 mb-4" color="black" small>logout</v-icon
              >Sign out
            </div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;</div>
          </div>
        </v-layout>
      </v-col>

      <v-col
        cols="12"
        lg="8"
        :class="$vuetify.breakpoint.lgAndUp ? 'ml-n5' : 'ml-2'"
      >
        <!-- <p style="font-size:14px;color:grey;" class="ml-2">Overview</p>
              <v-layout :justify-space-around="$vuetify.breakpoint.smAndUp" justify-center align-center class=" mr-10" :column="!$vuetify.breakpoint.smAndUp">
                  <v-card rounded="lg"  :width="$vuetify.breakpoint.smAndUp? '30%' : '60%'"  hover outlined class="mt-2 mb-2">  
                    <v-list-item class="">
                    <v-list-item-content>
                    <div style="font-size:22px; font-weight: bold;text-align: center; justify-text:center;">100</div>
                    <v-list-item-title style="font-size:16px;text-align: center;" class="mt-2">Total no. of calls</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
                 <v-card rounded="lg"  :width="$vuetify.breakpoint.smAndUp? '30%' : '60%'" hover outlined class="mt-2 mb-2">  
                    <v-list-item class="">
                    <v-list-item-content>
                    <div style="font-size:22px; font-weight: bold;text-align: center; justify-text:center;">100</div>
                    <v-list-item-title style="font-size:16px;text-align: center;" class="mt-2">No. of calls used</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
                 <v-card rounded="lg"  :width="$vuetify.breakpoint.smAndUp? '30%' : '60%'" hover outlined class="mt-2 mb-2">  
                    <v-list-item class="">
                    <v-list-item-content>
                    <div style="font-size:22px; font-weight: bold;text-align: center; justify-text:center;">100</div>
                    <v-list-item-title style="font-size:16px;text-align: center;" class="mt-2">No. of calls remaining</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-layout> -->
        <p style="font-size: 14px; color: grey" class="ml-2 mt-5">
          Your Activity
        </p>
        <br />
        <v-layout row class="ml-5 mt-n5 mr-10 mb-4" justify-start>
          <v-card id="api_usage"
            class="pa-0 ma-0 pb-n12"
            width="98%"
            outlined
            height=" auto"
            rounded="lg"
          >
            <v-layout justify-start class="ml-5 mt-3 mb-5">
              <v-radio-group
                v-model="graphData"
                :row="$vuetify.breakpoint.smAndUp"
              >
                <v-radio
                  class="ml-2 mr-6 mt-2"
                  label="In a year"
                  color="primary"
                  value="m"
                ></v-radio>
                <v-radio
                  class="ml-2 mr-6 mt-2"
                  label="In a month"
                  color="primary"
                  value="d"
                ></v-radio>
                <v-radio
                  class="ml-2 mr-6 mt-2"
                  label="In a day"
                  color="primary"
                  value="h"
                ></v-radio>
              </v-radio-group>
            </v-layout>
            <!-- <img src="@/assets/demoChart.svg" contain width="90%" class="mt-n10" > -->
            <charts :chartOptions="chartOptions" :chartType="chartType" :apiUsageChart="true" />

            <!-- <charts 
                  :chartData="chartData_monthly" 
                  :chartOptions="chartOptions" 
                  :chartType="chartType" /> -->
          </v-card>

          <v-card id="api_key"
            class="pa-0 ma-0 pb-n12 mt-4"
            width="98%"
            outlined
            rounded="lg"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="primary--text"
                  style="font-weight: bold"
                  >My API Key
                  <v-icon
                    style="float: right"
                    class="button"
                    small
                    @click="copy"
                    >content_copy</v-icon
                  ></v-list-item-title
                >
                <v-layout>
                  <v-list-item-subtitle class="black--text mt-4 mb-4">{{
                    accessKey
                  }}</v-list-item-subtitle>
                  <v-btn
                    text
                    style="text-decoration: underline; font-weight: 600"
                    small
                    class="ml-5 mt-2"
                    @click="showResetToken = true"
                  >
                    Reset-Key
                  </v-btn>
                </v-layout>
              </v-list-item-content></v-list-item
            >
          </v-card>

          <v-card id="subscription_plan"
            class="pa-0 ma-0 pb-n12 mt-4"
            width="98%"
            outlined
            rounded="lg"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="primary--text"
                  style="font-weight: bold"
                  >My Subscription Plan
                </v-list-item-title>
                <v-row>
                  <v-col cols="5" sm="3" md="3" lg="2">
                    <v-layout justify-space-between class="pr-n12">
                      <v-list-item-subtitle
                        class="black--text mt-4 mb-4"
                        style="font-weight: bold"
                        >My Plan</v-list-item-subtitle
                      >
                      <span
                        class="black--text mt-4 mb-4"
                        style="font-weight: bold"
                        >:</span
                      >
                    </v-layout>
                  </v-col>
                  <v-col>
                    <v-list-item-subtitle class="black--text mt-4 mb-4"
                      ><span>Free Plan</span></v-list-item-subtitle
                    >
                  </v-col>
                </v-row>

                <v-row class="mt-n4">
                  <v-col cols="5" sm="3" md="3" lg="2">
                    <v-layout justify-space-between>
                      <v-list-item-subtitle
                        class="black--text"
                        style="font-weight: bold"
                        >API Usage</v-list-item-subtitle
                      >
                      <span class="black--text" style="font-weight: bold"
                        >:</span
                      >
                    </v-layout>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-col cols="5" sm="2" md="1" lg="1" >
                        <div style="width:60px; height:9px;align-content:center;justify-content:center;" class="secondary mt-1">
                        <div :style="'width:' + (quota.assigned - quota.remaining) + '%; height:9px;align-content:center;justify-content:center;'" class="primary">

                        </div>
                        
                        
                        </div>
                    
                      </v-col>
                      <v-col>
                    <v-list-item-subtitle class="black--text ml-2 "
                      ><span>{{quota.assigned - quota.remaining}}/{{quota.assigned}}</span></v-list-item-subtitle
                    ></v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-list-item-content></v-list-item
            >
          </v-card>

          <v-card id="account_details"
            class="pa-0 ma-0 pb-n12 mt-4"
            width="98%"
            outlined
            rounded="lg"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  class="primary--text"
                  style="font-weight: bold"
                  >My Account Details
                  <v-icon
                    style="float: right"
                    class="button"
                    small
                    @click="$router.push({name: 'editAccount', params: {'userId': userInfo.id}})"
                    >edit</v-icon
                  >
                </v-list-item-title>
                <v-row>
                  <v-col cols="5" sm="3" md="3" lg="2">
                    <v-layout justify-space-between class="pr-n12">
                      <v-list-item-subtitle
                        class="black--text mt-4 mb-4"
                        style="font-weight: bold"
                        >Name</v-list-item-subtitle
                      >
                      <span
                        class="black--text mt-4 mb-4"
                        style="font-weight: bold"
                        >:</span
                      >
                    </v-layout>
                  </v-col>
                  <v-col>
                    <v-list-item-subtitle class="black--text mt-4 mb-4"
                      ><span>{{
                        userInfo.person_name
                      }}</span></v-list-item-subtitle
                    >
                  </v-col>
                </v-row>

                <v-row class="mt-n4">
                  <v-col cols="5" sm="3" md="3" lg="2">
                    <v-layout justify-space-between>
                      <v-list-item-subtitle
                        class="black--text"
                        style="font-weight: bold"
                        >Email</v-list-item-subtitle
                      >
                      <span class="black--text" style="font-weight: bold"
                        >:</span
                      >
                    </v-layout>
                  </v-col>
                  <v-col>
                    <v-list-item-subtitle class="black--text"
                      ><span>{{ userInfo.email }}</span></v-list-item-subtitle
                    >
                  </v-col>
                </v-row>

                <v-row class="mt-n4">
                  <v-col cols="5" sm="3" md="3" lg="2">
                    <v-layout justify-space-between>
                      <v-list-item-subtitle
                        class="black--text mt-4"
                        style="font-weight: bold"
                        >Username</v-list-item-subtitle
                      >
                      <span class="black--text mt-4" style="font-weight: bold"
                        >:</span
                      >
                    </v-layout>
                  </v-col>
                  <v-col>
                    <v-list-item-subtitle class="black--text mt-4"
                      ><span>{{
                        userInfo.username
                      }}</span></v-list-item-subtitle
                    >
                  </v-col>
                </v-row>
              </v-list-item-content></v-list-item
            >
          </v-card>
        </v-layout>
      </v-col>
    </v-row>
    <resetTokenDialog :show="showResetToken" @close="closeResetToken" ></resetTokenDialog>
  </div>
</template>

<script>
const charts = () => import("../components/chart.vue");
const resetTokenDialog = () => import ('../components/resetToken.vue')


export default {

  metaInfo: {
      vmid: 'main',
      title: 'Dashboard',
      content: 'This is an application for subscription of various apis!'
    },

  watch: {
    graphData: {
      handler(val){
        if(val){
          if(this.graphData.match('m')){
            this.$store.dispatch('getGraphData', 'month')
             
          }
          else{
             if(this.graphData.match('d')){
            this.$store.dispatch('getGraphData', 'day')
             
          }else{
            this.$store.dispatch('getGraphData', 'hour')
          }
           }
        }
      }
    },

    max: {
      handler(val){
        if(val){
          this.chartOptions = {
          responsive: true,
          scales: {
            xAxes: [
              {
                stacked: true,
                gridLines: { display: false },
              },
            ],
            yAxes: [
              {
                ticks: {
                  stepSize: (this.max + 10)/2,
                  min: 0,
                  max: this.max + 10,
                },
                stacked: true
              },
              
            ],},
            maintainAspectRatio: false,
          legend: {
          display: false
          },
          animation: {
            duration: 2000,
            easing: "easeInOutQuart",
          },
      }
    }

  },}},


  
  created: function(){
    this.graphData = 'm'
    this.$store.dispatch('getAccessKey')
    this.$store.dispatch('getRemainingQuota')
    this.chartOptions = {
          responsive: true,
          scales: {
            xAxes: [
              {
                stacked: true,
                gridLines: { display: false },
              },
            ],
            yAxes: [
              {
                ticks: {
                  stepSize: (this.max + 10)/2,
                  min: 0,
                  max: this.max + 10,
                },
                stacked: true
              },
              
            ],},
            maintainAspectRatio: false,
          legend: {
          display: false
          },
          animation: {
            duration: 2000,
            easing: "easeInOutQuart",
          },
      }
  },

  components: {
    charts,
    resetTokenDialog,
  },

  data: () => ({
    chartType: "line",
    chartData: {},
    chartOptions: {},
    graphData: '',
    showResetToken: false,


  }),

  computed: {
    accessKey: function(){
      var key = this.$store.state.userdata.accessKey
      var truncatedKey = key.substring(0,35) + ' ........... ' + key.substring(key.length - 35)
      return truncatedKey;
    },

    fullAccessKey: function(){
      return this.$store.state.userdata.accessKey
    },

    userInfo: function(){
      return this.$store.state.auth.userInfo;
    },

    quota: function(){
    return this.$store.state.userdata.quota['1_month'];
    },

    usedQuota: function(){
      return this.quota.assigned - this.quota.remaining;

    },

    max: function(){
      return this.$store.state.userdata.maxUsage;
    
    }


  },

  methods: {
   

    closeResetToken: function(){
      this.showResetToken = false;
    },

    logout: function(){
      this.$store.dispatch('logout')

    },

    copy: function(){
      navigator.clipboard.writeText(this.fullAccessKey)
      .then(() => { alert("Access key copied" );})
      .catch(() => {alert("Ooops something went wrong!" );})
     
    }
  }

}
</script>

<style>
.button {
  cursor: pointer;
}

a, a:link, a:visited{
  text-decoration: none;
  color: black;
  text-decoration-color: black;
}
</style>